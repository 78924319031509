/* The following block can be included in a custom.scss */
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
/* make the customizations */
$gray-100: #f1f5f9;
$gray-200: #f2f2f2;
$gray-300: #cbd5e1;
$gray-400: #94a3b8;
$gray-500: #64748b;
$gray-600: #475569;
$gray-700: #334155;
$gray-800: #1e293b;
$gray-900: #0f172a;
$light-blue: #26a3cc;
$off-blue: #c1f0ff;
$dark-blue: #061024;
$mid-dark-blue: #09314a;
$always-blue: #0b162c;
$green-shade: #0e6275;
$green: #3caa47;
$regular-white: #ffffff;
$off-white: #f4f4f4;

$theme-colors: (
  "primary": $dark-blue,
  "secondary": $light-blue,
  "warning": $green-shade,
  "light": $off-white,
  "dark": $green,
  "danger": $off-blue,
);

$container-max-widths: (
  sm: 540px,
  md: 700px,
  lg: 900px,
  xl: 1100px,
  xxl: 1100px,
);
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.2;
$h2-font-size: $font-size-base * 1.7;
$h3-font-size: $font-size-base * 1.45;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 0.85;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$border-radius: 0.45rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.3rem;
$card-cap-bg: rgba($regular-white, 0.03);

$font-family-base: "Poppins", sans-serif !important;

body {
  --bs-body-font-family: "Poppins", sans-serif;
  --bs-body-bg: $gray-200;
}

.header {
  background-color: #063441;
  //padding-top: 90px;
  //padding-bottom: 90px;
  //margin-bottom: 50px;
}

.content-container-dark {
  background-color: $dark-blue;
}

.header-small {
  background-color: #063441;
  padding-top: 30px;
  //padding-bottom: 30px;
  //margin-bottom: 50px;
}

.card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.card-bg-dark {
  background-color: $mid-dark-blue !important;
}
.blurred-bg {
  background-color: #3350648c;
}
.always-blue-bg {
  background-color: $always-blue;
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
